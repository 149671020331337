export default class UserDetails {
    constructor(id, firstname, lastname, username, email, password, gender, dateOfBirth, 
        contactNumber, addressline1, addressline2, town, county, postcode, file, ukpsf, jersey,
        teamid, divid, cardpurchased, teamcaptain, cardtransactions, lastyearsdivision, disclaimer, disclaimerdate,
        emergencycontactname, emergencycontactnumber) {
      this.username = username;
      this.email = email;
      this.password = password;
      this.firstname = firstname;
      this.lastname = lastname;
      this.gender = gender;
      this.dateOfBirth = dateOfBirth;
      this.contactNumber = contactNumber;
      this.addressline1 = addressline1;
      this.addressline2 = addressline2;
      this.town = town;
      this.county = county;
      this.postcode = postcode;
      this.file = file;
      this.ukpsf = ukpsf;
      this.jersey = jersey;
      this.teamid = teamid;
      this.divid = divid;
      this.cardpurchased = cardpurchased;
      this.teamcaptain = teamcaptain;
      this.cardtransactions = cardtransactions;
      this.lastyearsdivision = lastyearsdivision;
      this.disclaimer = disclaimer;
      this.disclaimerdate = disclaimerdate;
      this.emergencycontactname = emergencycontactname;
      this.emergencycontactnumber = emergencycontactnumber;
    }
  }