//import axios from 'axios';
import multipartAuthHeader from './multipart.auth.header';
//import authHeader from './auth.header';
import api from './api';

//const API_URL = 'http://127.0.0.1:8080/api/secure';

class UploadFilesService {
  upload(file) {
    let formData = new FormData();

    formData.append("file", file);

    return api.post('secure/file/upload', formData, {
        headers: multipartAuthHeader()
    });
  }

  uploadTeam(file, team, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);

    return api.post('secure/file/upload/' + team, formData, {
        headers: multipartAuthHeader(),
      onUploadProgress
    });
  }

  getFiles() {
    return api.get('secure/file');
  }

  getTeamFiles(team) {
    return api.get('secure/file/team/' + team);
  }

}

export default new UploadFilesService();